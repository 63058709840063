import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from 'axios';

import { CONFIG } from '@config';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig {
  securityWorker?: () =>
    | Promise<AxiosRequestConfig | void>
    | AxiosRequestConfig
    | void;
  secure?: boolean;
  format?: ResponseType;
  instance?: AxiosInstance;
  axiosConfig?: Omit<AxiosRequestConfig, 'data' | 'cancelToken'>;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient {
  public instance: AxiosInstance;
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    axiosConfig,
    instance,
  }: ApiConfig = {}) {
    this.instance =
      instance ||
      axios.create({
        ...axiosConfig,
        baseURL: axiosConfig?.baseURL || CONFIG.API_BASE_URL || '',
      });
    this.secure = secure;
    this.format = format;
    if (securityWorker) this.securityWorker = securityWorker;
  }

  private securityWorker: ApiConfig['securityWorker'] = () => {
    // const { access, isRegister } = getToken();
    // const isLogged = !!access && isRegister;
    // if (isLogged)
    //   return {
    //     headers: {
    //       Authorization: 'Bearer ' + access,
    //     },
    //   };
  };

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    /**
     *  TODO: kidsnote 로컬 환경 실행 시 cors 에러를 발생시키는 부분 삭제 - (라이트하우스에서는 해당 코드를 사용중이라 주석 처리)
     *  ____________________ start ____________________*/
    // if(params.headers === undefined) {
      params.headers = {};
    // }
    // if(params.headers.Authorization === undefined) {
    //   params.headers.Authorization = '';
    // }
    if(location.href.includes('/notice_talk')) {
      params.headers = {
        'KAKAO-TALK': ' Origin-Directory /notice_talk/ '
      };
    }
    /* ____________________ end ____________________ */
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker())) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}
