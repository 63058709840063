import { useRouter } from 'next/router';
import React, { memo } from 'react';

import {
  ContainerProps,
} from '@chakra-ui/react';

import { useUserMeRetrieveQuery } from '@apis/User';

import { listType } from '../InnerTab/InnerTab';
import dynamic from 'next/dynamic';

const HomeLayoutKDS = dynamic(() => import('@components/common/@Layout/HomeLayoutKDS/HomeLayoutKDS'));

interface HomeLayoutProps {
  content?: JSX.Element;
  tabList?: listType[];
  title?: string;
  containerProps?: ContainerProps;
}

const HomeLayout = ({ content }: HomeLayoutProps) => {
  const router = useRouter();

  const { isLoading } = useUserMeRetrieveQuery({
    options: {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (e) => {
        if (e.response?.status === 401) {
          router.replace(process.env.NEXT_PUBLIC_LOGIN_REDIRECT_URL as string);
        }
      },
    },
  });

  return (
    <HomeLayoutKDS isLoading={isLoading}>
      {content}
    </HomeLayoutKDS>
  );
};

export default memo(HomeLayout);
