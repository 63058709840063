import axios from 'axios';

import { CONFIG } from '@config';

// import { apiLogger } from '@utils/apiLogger';
// import styledConsole from '@utils/styledConsole';
import { getToken } from '@utils/localStorage/token';

import authController from './../auth/AuthApi.controller';

// const isDev = CONFIG.ENV === 'development';

const instance = axios.create({
  baseURL: CONFIG.API_BASE_URL,
  /**
   * https://github.com/axios/axios/blob/main/README.md#request-config
   * `timeout` specifies the number of milliseconds before the request times out.
   * If the request takes longer than `timeout`, the request will be aborted.
   */
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  paramsSerializer: function (paramObj) {
    const params = new URLSearchParams();

    Object.keys(paramObj).forEach((key) => {
      const value = paramObj[key];
      if (value === undefined) return;
      if (Array.isArray(value)) {
        value.forEach((v, idx) => params.append(key, v));
        return;
      }
      params.append(key, value);
    });
    return params.toString();
  },
});

const setAuthHeader = (token: string) => {
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};

// const { openModal } = useModal();

const unsetAuthHeader = () => {
  delete instance.defaults.headers.common['Authorization'];
};

type Request = (access: string) => void;
let isTokenRefreshing = false;
let refreshSubscribers: Request[] = [];

const onTokenRefreshed = (access: string | null) => {
  if (access !== null) {
    refreshSubscribers.map((callback: Request) => callback(access));
  }
};

const addRefreshSubscriber = (callback: Request) => {
  refreshSubscribers.push(callback);
};

instance.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    const isAccess = !!token && !!token.access;
    if (isAccess) setAuthHeader(token.access as string);
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

let is403ErrorHandled = false;
instance.interceptors.response.use(
  (res) => {
    // const { status, config: reqData, data: resData } = res;
    // if (isDev) apiLogger({ status, reqData, resData });
    return res;
  },
  async (error) => {
    try {
      const { response: res, config: reqData } = error || {};
      const { status } = res || {};
      const isUnAuthError = status === 401;
      const isUnEmployeeError = status === 403;
      const isNotFound = status === 404;
      const isExpiredToken = status === 444;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // const isDev = CONFIG.ENV === 'dev' || CONFIG.ENV === 'development';
      // if (isDev)
      //   apiLogger({ status, reqData, resData: error, method: 'error' });

      if (isExpiredToken) {
        const retryOriginalRequest = new Promise((resolve) => {
          addRefreshSubscriber((access: string) => {
            reqData.headers.Authorization = 'Bearer ' + access;
            resolve(instance(reqData));
          });
        });

        if (!isTokenRefreshing) {
          try {
            isTokenRefreshing = true;
            const token = await authController.refreshToken();
            isTokenRefreshing = false;
            if (token) {
              onTokenRefreshed(token.access);
              refreshSubscribers = [];
            }
          } catch (error) {
            console.log(error);
          }
        }

        return retryOriginalRequest;
      }

      if (isUnEmployeeError && !is403ErrorHandled) {
        is403ErrorHandled = true;
        alert('시설장의 승인을 받은 후 시도해주세요.');
        return window.location.replace(
          `${process.env.NEXT_PUBLIC_FN_WEB_MAIN_HOST}/service`,
        );
      }

      if (isUnAuthError || isNotFound) {
        // deleteToken();
        // if (isClient) Router.push(ROUTE.LOGIN);
        return Promise.reject(error);
      }

      const errorMessage = String(Object.values(error.response?.data)?.[0]);
      // openModal(AlertPopup, {
      //   auxProps: {},
      // });
      if (errorMessage === '[object Object]') {
        const value = Object.values(error.response?.data)?.[0];
        // alert(
        //   Object.values(value as Object)?.[0]?.[0] || '요청에 실패했습니다.',
        // );
        console.log(
          Object.values(value as Object)?.[0]?.[0] || '요청에 실패했습니다.',
        );
      } else if (!errorMessage?.startsWith('<') || errorMessage?.length > 100) {
        console.log(errorMessage);
        // alert(errorMessage);
      }
      return Promise.reject(error);
    } catch (e) {
      // styledConsole({
      //   //
      //   method: 'error',
      //   topic: 'UN_HANDLED',
      //   title: 'axios-interceptor',
      //   data: e,
      // });
    }
  },
);

export { setAuthHeader, unsetAuthHeader };

export default instance;
