import instance from '@apis/_axios/instance';

import { UserMeType } from '../@types/data-contracts';
import { HttpClient, RequestParams } from '../http-client';

import { useQuery } from 'react-query';
import { QueryHookParams } from '../@types/react-query-type';

export class UserApi extends HttpClient {
  /**
   * No description
   *
   * @tags user
   * @name UserMeRetrieve
   * @summary 유저 내정보 조회 O
   * @request GET:/v1/user/me/
   */
  userMeRetrieve = async (variables?: { params?: RequestParams }) => {
    const { data } = await this.request<UserMeType, any>({
      path: `/v1/user/me/`,
      method: 'GET',
      format: 'json',
      ...variables?.params,
    });

    return data;
  };
}

export const userApi = new UserApi({ instance });

// ========================HOOKS========================

/**
 * QUERY_KEYS
 */
export const QUERY_KEY_USER_API = {
  ME_RETRIEVE: (variables?: Parameter<typeof userApi.userMeRetrieve>) => [
    'USER_ME_RETRIEVE',
    variables,
  ],
} as const;

/**
 * No description
 *
 * @tags user
 * @name UserMeRetrieve
 * @summary 유저 내정보 조회 O
 * @request GET:/v1/user/me/
 */
export const useUserMeRetrieveQuery = (
  params?: QueryHookParams<typeof userApi.userMeRetrieve>,
) => {
  const queryKey = QUERY_KEY_USER_API.ME_RETRIEVE(params?.variables);
  const result = useQuery(
    queryKey,
    () => userApi.userMeRetrieve(params?.variables),
    params?.options,
  );

  return { ...result, queryKey };
};
